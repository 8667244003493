.balloon {
  height: max-content;
  width: max-content;
  min-width: 200px;
  padding: 8px 8px;
  color: #333333;
  text-align: left;
  vertical-align: middle;
  background-color: #fffff6;
  border: 1px solid #cdb7b5;
  border-radius: 6px;
  font-size: 12px;
  font-family: "Arial";
}

dt,
dd {
  display: inline;
}

dd:after {
  content: " ";
  display: block;
}

dt {
  font-weight: 700;
}

.box {
  display: flex;
  justify-content: flex-end;
}

.close {
  color: #ccccc5;
  font-size: 20px;
  cursor: pointer;
}

.wrap {
  display: grid;
  column-gap: 4px;
  grid-template-columns: 1fr 1fr;
}
